import getMenuItems from "api/requests/wordpress/getMenuItems";
import getPages from "api/requests/wordpress/getPages";
import { MenuItems, Page, RegionRestriction } from "custom-types/Wordpress";
import { createGetServerSideProps } from "utils/createGetServerSideProps";
import {
  redirectToPreviewPage,
  regionRestrictionRedirect,
} from "utils/news/redirect";
import { getCountryCodeHeader } from "utils/requestContext";

import ArticleContent from "components/news/ArticleContent";
import PageLayout from "components/news/Layouts/PageLayout";

const NEWS_PAGE_ID = "6885";

type CategoryPageProps = {
  page: Page;
  menuItems: MenuItems;
};

const CategoryPage: React.FC<CategoryPageProps> = ({ page, menuItems }) => {
  return (
    <PageLayout menuItems={menuItems} page={page}>
      <ArticleContent item={page} />
    </PageLayout>
  );
};

export const getServerSideProps = createGetServerSideProps<CategoryPageProps>(
  async (context) => {
    const {
      params: { categorySlug } = {
        categorySlug: "",
      },
      query: { preview_id, preview, page_id },
      resolvedUrl,
    } = context;

    const isPreview =
      (preview_id || page_id) && (preview === "true" || preview === "1");
    if (isPreview) {
      return redirectToPreviewPage((preview_id || page_id) as string, "page");
    }

    const countryCode = getCountryCodeHeader(context.req.headers);

    const { pages } = await getPages(
      countryCode,
      { parent: NEWS_PAGE_ID, slug: `${categorySlug}` },
      "1",
      "1",
    );
    const page = pages?.[0];
    if (!page) {
      return {
        notFound: true,
      };
    }

    const { regionRestriction, regionRestrictionRedirectUrl } = page;
    if (
      regionRestrictionRedirectUrl ||
      (regionRestriction !== RegionRestriction.WW &&
        regionRestriction !== countryCode)
    ) {
      return regionRestrictionRedirect(
        resolvedUrl,
        regionRestriction,
        regionRestrictionRedirectUrl,
      );
    }

    const menuItems = await getMenuItems(
      countryCode === "CA" ? "canada" : "global",
    );

    return {
      props: {
        menuItems,
        page,
      },
    };
  },
);

export default CategoryPage;
